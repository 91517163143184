import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment'; // Novo ícone de relatórios
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  TextField,
  MenuItem,
  Container,
  ListItemIcon
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Sidebar from '../components/Sidebar'; // Importa o Sidebar
import Header from '../components/Header';
import Footer from '../components/Footer'; // Importe o Footer





const drawerWidth = 240;


const useStyles = {
  content: {
    flexGrow: 1,
    padding: '24px',
    marginLeft: 240, // Compensa a largura do drawer
    marginTop: '80px', // Compensa a altura do header
    marginBottom: '15px', // Compensa a altura do footer
  },
  
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    p: 4,
    borderRadius: '8px',
  },
  table: {
    marginTop: '20px',
  },
};

const Dashboard = () => {
  const [statusList, setStatusList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [modalType, setModalType] = useState(''); // 'edit' ou 'delete' ou 'add'
  const [nomeMotorista, setNomeMotorista] = useState('');
  const [placa, setPlaca] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  // Carregar dados da API
  useEffect(() => {
    fetchStatusList();
    const userProfile = localStorage.getItem('userProfile');
    if (!userProfile) {
      navigate('/login');
    }

  }, []); // Chama apenas na montagem do componente
  const handleLogout = () => {
    localStorage.removeItem('userProfile'); // Remove o perfil do usuário
    navigate('/login'); // Redireciona para a tela de login
  };
  
  
  
  const fetchStatusList = async () => {
    try {
      const response = await axios.get(
        'https://coopergraos.com.br/api_nova/processa.php?action=fetch'
      );
  
      const validStatusList = response.data.filter(
        (item) => item.nome_moto && item.placa && item.status
      );
  
      setStatusList(validStatusList); // Armazena apenas registros válidos
    } catch (error) {
      console.error('Erro ao buscar lista de status:', error);
      setStatusList([]);
    }
  };
  
  
  
  

  // Abrir o modal
  const handleOpenModal = (type, status = null) => {
    setModalType(type);
    setSelectedStatus(status);
    setNewStatus(status?.status || '');
    setNomeMotorista(status?.nome_moto || '');
    setPlaca(status?.placa || '');
    setOpenModal(true);
  };

  // Fechar o modal e limpar os campos
  const handleCloseModal = () => {
    setOpenModal(false);
    resetForm(); // Limpar os estados do formulário
  };

  // Resetar os campos do formulário
  const resetForm = () => {
    setSelectedStatus(null);
    setNewStatus('');
    setNomeMotorista('');
    setPlaca('');
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    if (!nomeMotorista || !placa || !newStatus) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }
  
    try {
      let url = 'https://coopergraos.com.br/api_nova/processa.php';
      let payload = {
        nome_moto: nomeMotorista,
        placa,
        status: newStatus,
      };
  
      if (modalType === 'edit') {
        payload.id = selectedStatus.id;
        url += '?action=update';
      } else if (modalType === 'add') {
        url += '?action=add';
      }
  
      const response = await axios.post(url, payload);
      const resultItem = response.data; // Recebe o item atualizado/adicionado do backend
  
      if (modalType === 'edit') {
        // Atualiza o item editado no estado local
        setStatusList((prevList) =>
          prevList.map((item) => (item.id === resultItem.id ? resultItem : item))
        );
      } else if (modalType === 'add') {
        // Adiciona o novo item à lista
        setStatusList((prevList) => [...prevList, resultItem]);
      }
  
      setErrorMessage('');
      handleCloseModal(); // Fecha o modal após a operação
  
      // Força a atualização da lista para garantir consistência
      await fetchStatusList();
    } catch (error) {
      setErrorMessage('Erro ao enviar os dados.');
      console.error('Erro:', error);
    }
  };
  
  
  
  const handleDeleteStatus = async () => {
    try {
      await axios.post(
        'https://coopergraos.com.br/api_nova/processa.php?action=delete',
        { id: selectedStatus.id }
      );
  
      // Recarregar a lista para garantir que os dados estejam atualizados
      await fetchStatusList();
      
      setErrorMessage('');
      handleCloseModal();
    } catch (error) {
      setErrorMessage('Erro ao remover status.');
      console.error('Erro ao remover status:', error);
    }
  };
  
  

  return (
    <>
      <CssBaseline />
      <Header pageTitle="Dashboard" /> {/* Header com título da página */}

      <Sidebar handleLogout={handleLogout} /> {/* Sidebar reutilizável */}

    

      <main style={useStyles.content}>
        <Container>
          {/* Botão de adicionar acima da tabela */}
          <div style={useStyles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={() => handleOpenModal('add')}
              sx={{ textTransform: 'none', backgroundColor: '#34a853' }} // Verde Google
            >
              Adicionar Motorista
            </Button>
          </div>

          <TableContainer component={Paper} sx={useStyles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Placa</TableCell>
                  <TableCell>Nome do Motorista</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
  {statusList.map((status, index) => (
    status && status.id && (
      <TableRow key={index}>
        <TableCell>{status.id}</TableCell>
        <TableCell>{status.placa || 'N/A'}</TableCell>
        <TableCell>{status.nome_moto || 'N/A'}</TableCell>
        <TableCell>{status.status || 'N/A'}</TableCell>
        <TableCell>
          <Button
            variant="text"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => handleOpenModal('edit', status)}
            sx={{ textTransform: 'none' }}
          >
            Editar
          </Button>
          <Button
            variant="text"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => handleOpenModal('delete', status)}
            sx={{ textTransform: 'none', marginLeft: '10px' }}
          >
            Remover
          </Button>
        </TableCell>
      </TableRow>
    )
  ))}
</TableBody>


            </Table>
          </TableContainer>
        </Container>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={useStyles.modalBox}>
            <Typography variant="h6" sx={useStyles.modalTitle}>
              {modalType === 'edit' ? 'Editar Motorista' : modalType === 'add' ? 'Adicionar Motorista' : 'Remover Motorista'}
            </Typography>

            {modalType === 'edit' || modalType === 'add' ? (
              <>
                <TextField
                  label="Nome do Motorista"
                  value={nomeMotorista}
                  onChange={(e) => setNomeMotorista(e.target.value)}
                  fullWidth
                  sx={useStyles.inputField}
                />
                <TextField
                  label="Placa"
                  value={placa}
                  onChange={(e) => setPlaca(e.target.value)}
                  fullWidth
                  sx={useStyles.inputField}
                />
                <TextField
                  label="Status"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  fullWidth
                  select
                  sx={useStyles.inputField}
                >
                  <MenuItem value="LIBERADO">LIBERADO</MenuItem>
                  <MenuItem value="AGUARDANDO">AGUARDANDO</MenuItem>
                  <MenuItem value="COMPARECER NO ESCRITORIO">
                    COMPARECER NO ESCRITORIO
                  </MenuItem>
                </TextField>
              </>
            ) : modalType === 'delete' ? (
              <Typography>
                Deseja remover o motorista <strong>{selectedStatus?.nome_moto}</strong>?
              </Typography>
            ) : null}

            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            <Box mt={2}>
              {modalType === 'edit' || modalType === 'add' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ textTransform: 'none' }}
                >
                  {modalType === 'edit' ? 'Atualizar' : 'Adicionar'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteStatus}
                  sx={{ textTransform: 'none' }}
                >
                  Remover
                </Button>
              )}
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseModal}
                sx={{ marginLeft: '10px', textTransform: 'none' }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Modal>
      </main>
      <Footer /> {/* Adicione o Footer aqui */}

    </>
  );
};

export default Dashboard;
