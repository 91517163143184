import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

const Login = ({ setUserProfile }) => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://coopergraos.com.br/api_nova/valida.php',
        { email, senha },
        { withCredentials: true }
      );

      if (response.data.success) {
        // Salvar perfil do usuário no localStorage
        localStorage.setItem('userProfile', JSON.stringify(response.data));
        
        // Salvar nível de acesso diretamente
        localStorage.setItem('nivelAcesso', response.data.nivelAcesso);

        // Redirecionar para o dashboard
        window.location.href = '/dashboard';
      } else {
        setErrorMessage('Usuário ou senha inválidos');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setErrorMessage('Erro ao conectar com o servidor');
    }
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <img src="https://athix.com.br/img/bg_cooperfibra.png" id="icon" alt="User Icon" />
        </div>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="fadeIn second"
            placeholder="Usuário"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            className="fadeIn third"
            placeholder="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
          <input type="submit" className="fadeIn fourth" value="Entrar" />
        </form>

        {errorMessage && <p className="text-center text-danger">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Login;
