// src/components/Footer.js

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Ícone do WhatsApp
import ListIcon from '@mui/icons-material/List'; // Ícone de lista/tarefas

const useStyles = {
  footer: {
    backgroundColor: '#f2f2f2',
    color: 'blue',
    textAlign: 'center',
    padding: '16px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  floatingLink: {
    position: 'fixed',
    bottom: '100px', // Espaço acima do botão do WhatsApp
    right: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: '#4285F4', // Azul Google
    color: '#FFFFFF',
    padding: '8px 12px',
    borderRadius: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000, // Acima de outros elementos
  },
  floatingWhatsApp: {
    position: 'fixed',
    bottom: '35px',
    right: '20px',
    backgroundColor: '#25D366',
    borderRadius: '50%',
    width: '56px',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    zIndex: 1000,
  },
};

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Ano dinâmico

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/5566999960422', '_blank'); // Abre o WhatsApp
  };

  return (
    <>
      {/* Footer */}
      <Box sx={useStyles.footer}>
        <Typography variant="body2">
          Desenvolvido por{' '}
          <Link href="https://www.athix.com.br" target="_blank" color="inherit">
            ATHIX
          </Link>{' '}
          - {currentYear}
        </Typography>
      </Box>

      {/* Link Flutuante para Acompanhamento */}
      <Link
        href="https://app.todoist.com/app/project/motoristas-app-6WPpMGMphGvQFQfq"
        target="_blank"
        sx={useStyles.floatingLink}
      >
        <ListIcon sx={{ fontSize: '24px' }} /> {/* Ícone de lista */}
        <Typography>Acompanhar Projeto</Typography>
      </Link>

      {/* Botão Flutuante do WhatsApp */}
      <Box sx={useStyles.floatingWhatsApp} onClick={handleWhatsAppClick}>
        <WhatsAppIcon sx={{ color: '#FFFFFF', fontSize: '32px' }} />
      </Box>
    </>
  );
};

export default Footer;
