import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableHead, TableRow, TableCell, TableBody,
  Button, TextField, Select, MenuItem, Box, Modal, Typography,
  Pagination, InputAdornment, IconButton, TableFooter, Autocomplete 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const ControleEmbarque = () => {
  const [embarques, setEmbarques] = useState([]);
  const [filteredEmbarques, setFilteredEmbarques] = useState([]);
  const [motoristas, setMotoristas] = useState([]); // Estado para armazenar os motoristas da API
  const [currentEmbarque, setCurrentEmbarque] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('visualizar'); // 'visualizar' | 'editar' | 'adicionar'
  const itemsPerPage = 12;
  const [nivelAcesso, setNivelAcesso] = useState(null);

  useEffect(() => {
    fetchEmbarques();
    fetchMotoristas(); // Carregar motoristas ao montar o componente
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  setNivelAcesso(userProfile ? userProfile.nivelAcesso : null);

  // Carrega os embarques ao montar o componente
  fetchEmbarques();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, embarques]);

  useEffect(() => {
    console.log("Embarques filtrados:", filteredEmbarques);
  }, [filteredEmbarques]);
  
  

   const fetchEmbarques = async () => {
    try {
      const response = await axios.get('https://coopergraos.com.br/api_nova/controle_embarque.php');
      console.log("Resposta completa da API:", response);
  
      if (response.data && Array.isArray(response.data)) {
        // Ordena os embarques: data mais recente primeiro, hora mais recente primeiro
        const sortedEmbarques = response.data.sort((a, b) => {
          // Comparar pelas datas (mais recente primeiro)
          const dateA = new Date(a.data);
          const dateB = new Date(b.data);
  
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
  
          // Comparar pelas horas (mais recente primeiro dentro da mesma data)
          const timeA = a.hora_chegada || "00:00:00";
          const timeB = b.hora_chegada || "00:00:00";
  
          if (timeA > timeB) return -1;
          if (timeA < timeB) return 1;
  
          // Desempate: Ordenar por ID (crescente)
          return b.id - a.id;
        });
  
        setEmbarques(sortedEmbarques);
        setFilteredEmbarques(sortedEmbarques); // Atualizar a tabela visível
      } else {
        console.error("Os dados retornados não são um array:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar embarques:", error.response?.data || error.message);
    }
  };
  
  
  
  
  const fetchMotoristas = async () => {
    // Fazer requisição para obter motoristas
    const response = await axios.get('https://coopergraos.com.br/api_nova/motoristas.php');
    setMotoristas(response.data);
  };
// Função de pesquisa aprimorada para aceitar qualquer valor (letras, números, datas)
const handleSearch = () => {
  const value = searchTerm.trim().toLowerCase();

  if (value === '') {
    setFilteredEmbarques(embarques); // Use o estado `embarques` aqui
  } else {
    const filtered = embarques.filter((emb) =>
      Object.keys(emb).some((key) => {
        let field = emb[key];

        if (!field) return false;

        // Formata campos de data para comparação mais ampla
        if (['data', 'hora_chegada', 'hora_entrada', 'hora_saida'].includes(key)) {
          field = new Date(field).toLocaleDateString('pt-BR');
        }

        return field.toString().toLowerCase().includes(value);
      })
    );
    setFilteredEmbarques(filtered);
  }
};




const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};



  
  
  
  

  const handleOpenModal = (type, embarque = null) => {
    setModalType(type);
    setCurrentEmbarque(
      embarque || {
        data: new Date().toISOString().slice(0, 10), // Data atual bloqueada para edição
        transportadora: '',
        motorista: '',
        placas: '',
        hora_chegada: getLocalTimeInCuiaba(), // Define a hora local de Cuiabá
        hora_entrada: '',
        hora_saida: '',
        nfe: '',
        empresa: '',
        status: 'Aguarde Liberação p/ carregamento', // Status inicial

        produto: ''
      }
    );
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const getLocalTimeInCuiaba = () => {
    const now = new Date();
    const options = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'America/Cuiaba' };
    const [hour, minute] = now.toLocaleTimeString('pt-BR', options).split(':');
    return `${hour}:${minute}`;
  };

  // Função para selecionar o motorista e atualizar a placa
  const handleMotoristaChange = (event, newValue) => {
    setCurrentEmbarque((prevEmbarque) => ({
      ...prevEmbarque,
      motorista: newValue ? newValue.nome.toUpperCase() : '', // Nome do motorista em maiúsculas
      placas: newValue ? newValue.placa.toUpperCase() : ''    // Placa em maiúsculas
    }));
  };
  
  
  
  

  const handleEmbarqueChange = (e) => {
    const { name, value } = e.target;
  
    setCurrentEmbarque((prevEmbarque) => ({
      ...prevEmbarque,
      [name]: name === 'placas' 
        ? value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() // Placas apenas letras e números, em maiúsculas
        : value,
    }));
  };
  

  const handleSalvarEmbarque = async () => {
    if (!currentEmbarque?.produto) {
      alert("Por favor, selecione o produto antes de salvar.");
      return;
    }
  
    try {
      if (modalType === 'editar') {
        await axios.put('https://coopergraos.com.br/api_nova/controle_embarque.php', currentEmbarque);
      } else {
        await axios.post('https://coopergraos.com.br/api_nova/controle_embarque.php', currentEmbarque);
      }
  
    
      // Atualiza os embarques e fecha o modal
      fetchEmbarques();
      // Envia notificação para o motorista
    enviarNotificacao(currentEmbarque);
  
      // Verifica o status e define o tipo do modal para "imprimir"
      if (currentEmbarque.status === 'Liberado - Buscar NF-e') {
        setModalType('imprimir');
        setOpenModal(true); // Garante que o modal reabra no modo de impressão
      } else {
        handleCloseModal();
      }
    } catch (error) {
      console.error("Erro ao salvar o embarque:", error);
    }
  };
  const handleSaveAndPrint = async () => {
    await handleSalvarEmbarque();
    if (currentEmbarque.status === 'Liberado - Buscar NF-e') {
      setModalType('imprimir');
    }
  };
  
  const handlePrintModal = () => {
    const modalContent = document.getElementById('printable-modal').innerHTML;
    const printWindow = window.open('', '_blank');
  
    printWindow.document.write(`
      <html>
        <head>
          <title>Impressão de Liberação</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              text-align: center;
              font-size: 21px;
              line-height: 1;
              margin: 0;
              padding: 15px;
            }
            .footer-text {
              margin-bottom: 5px; /* Espaço entre o texto e a linha */
            }
            .signature-line {
              margin-top: 50px; /* Espaço entre a linha e o texto acima */
              margin-bottom: 5px; /* Espaço entre a linha e o próximo elemento */
            }
            @media print {
              body {
                font-size: 21px;
                line-height: 1;
              }
              .footer-text {
                margin-bottom: 60px; /* Espaço na impressão */
              }
              .signature-line {
            margin-top: 60px; /* Espaço entre o texto acima e a linha */
          }
          .below-line-text {
            margin-top: 5px; /* Espaço entre a linha e o texto "Carimbo e Assinatura do Liberador" */
          }
            }
          </style>
        </head>
        <body>
          <div>
            ${modalContent}
          </div>
        </body>
      </html>
    `);
  
    printWindow.document.close();
    printWindow.print();
  };
  
  
  const enviarNotificacao = async (embarque) => {
    try {
      await axios.post('https://coopergraos.com.br/api_nova/notificacao.php', {
        motorista: embarque.motorista,
        status: embarque.status
      });
      console.log("Notificação enviada para o motorista:", embarque.motorista);
    } catch (error) {
      console.error("Erro ao enviar notificação:", error);
    }
  };
  
  

  const handleDelete = async (id) => {
    await axios.delete('https://coopergraos.com.br/api_nova/controle_embarque.php', { data: { id } });
    fetchEmbarques();
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; 
  
    const [year, month, day] = dateString.split('-'); 
    return `${day}/${month}/${year}`; 
  };
  
  

  const paginatedEmbarques = filteredEmbarques.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  

  return (
    <>
      <Header pageTitle="Controle de Embarque" />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box sx={{ flexGrow: 1, p: 4 }}>
          <h1>.</h1>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenModal('adicionar')}
            sx={{ mb: 2 }}
          >
            Adicionar Embarque
          </Button>

          <TextField
            placeholder="Digite qualquer informação para buscar"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
<Modal open={openModal} onClose={handleCloseModal}>
<Box
    id="printable-modal" // Adiciona um ID ao modal
    sx={{
      width: '80%',
      maxWidth: 800,
      margin: 'auto',
      mt: 10,
      p: 3,
      backgroundColor: 'white',
      borderRadius: 2,
      overflowY: 'auto',
      maxHeight: '80vh',
      textAlign: 'center',
    }}
  >
    {modalType === 'imprimir' ? (
      <>
        <Typography variant="h5" sx={{ fontSize: '16px', mb: 4 }}>Liberação para Saída</Typography>
        <img 
          src="http://www.athix.com.br/img/bg_cooperfibra.png" 
          alt="Logo" 
          style={{ width: '100px', marginBottom: '15px' }} 
        />

        <Typography sx={{ fontSize: '15px', marginBottom: '2px' }}>
          <strong>Data:</strong> {new Date().toLocaleDateString('pt-BR')}
        </Typography>
        <Typography sx={{ fontSize: '15px', marginBottom: '2px' }}>
          <strong>Motorista:</strong> {currentEmbarque.motorista || ''}
        </Typography>
        <Typography sx={{ fontSize: '15px', marginBottom: '2px' }}>
          <strong>Placa:</strong> {currentEmbarque.placas}
        </Typography>
        <Typography sx={{ fontSize: '15px', marginBottom: '2px' }}>
          <strong>Produto:</strong> {currentEmbarque.produto}
        </Typography>
        <Typography sx={{ fontSize: '15px', marginBottom: '2px' }}>
          <strong>NF-e:</strong> {currentEmbarque.nfe}
        </Typography>
        <Typography sx={{ fontSize: '15px', marginBottom: '2px' }}>
          <strong>Status:</strong> {currentEmbarque.status}
        </Typography>

        <Typography sx={{ fontSize: '19px', mt: 3 }}>
  Sr. Motorista, por favor entregar essa liberação ao guarda apenas com o caminhão alinhado para saída.
</Typography>

<Typography
  sx={{ fontSize: '19px', mt: 5 }} // Define o espaço entre o texto acima e a linha
  className="signature-line"
>
  ____________________________
</Typography>

<Typography
  sx={{ fontSize: '19px', mt: 2 }} // Define o espaço entre a linha e o texto abaixo
  className="below-line-text"
>
  Carimbo e Assinatura do Liberador
</Typography>


        <Button
          variant="contained"
          color="primary"
          onClick={handleSalvarEmbarque}
          sx={{ mt: 2, mr: 2 }}
        >
          Salvar
        </Button>
        <Button
  variant="contained"
  onClick={handlePrintModal} // Alterado para chamar handlePrintModal
  sx={{ mt: 2 }}
>
  Imprimir
</Button>

        <Button
          variant="outlined"
          onClick={handleCloseModal}
          sx={{ mt: 2, ml: 2 }}
        >
          Fechar
        </Button>
      </>
    ) : (
      // Conteúdo para adicionar/editar o embarque
      <>
        {/* Coloque aqui o conteúdo de adicionar/editar */}
      



            <Typography variant="h6">
              {modalType === 'adicionar' ? 'Adicionar Embarque' : 'Editar Embarque'}
            </Typography>

            <TextField
              label="Transportadora"
              name="transportadora"
              value={currentEmbarque?.transportadora || ''}
              onChange={(e) =>
                handleEmbarqueChange({
                  target: { name: 'transportadora', value: e.target.value.toUpperCase() },
                })
              }
              fullWidth
              margin="normal"
            />

<Autocomplete
  options={motoristas}
  getOptionLabel={(option) => option.nome ? option.nome.toUpperCase() : ''} // Exibe o nome em maiúsculas
  onChange={handleMotoristaChange}
  renderInput={(params) => (
    <TextField {...params} label="Motorista" fullWidth margin="normal" />
  )}
  value={
    motoristas.find((motorista) => motorista.nome.toUpperCase() === currentEmbarque.motorista) || null
  }
/>

<TextField
  label="Placas"
  name="placas"
  value={currentEmbarque.placas || ''} // Exibe a placa preenchida automaticamente
  fullWidth
  margin="normal"
  InputProps={{
    readOnly: true, // Define o campo como somente leitura
  }}
/>

            <Select
              label="Produto"
              name="produto"
              value={currentEmbarque?.produto || ''}
              onChange={handleEmbarqueChange}
              fullWidth
              margin="normal"
              required
              error={!currentEmbarque.produto} // Exibe erro visual se o campo estiver vazio
              helperText={!currentEmbarque.produto ? 'Produto é obrigatório' : ''} // Texto de ajuda caso não seja preenchido
            >
              <MenuItem value="SOJA">SOJA</MenuItem>
              <MenuItem value="MILHO">MILHO</MenuItem>
              <MenuItem value="RESIDUO">RESÍDUO</MenuItem>
            </Select>


            <TextField
              label="Empresa"
              name="empresa"
              value={currentEmbarque?.empresa || ''}
              onChange={(e) =>
                handleEmbarqueChange({
                  target: { name: 'empresa', value: e.target.value.toUpperCase() },
                })
              }
              fullWidth
              margin="normal"
            />

            <TextField
              label="NF-e"
              name="nfe"
              value={currentEmbarque?.nfe || ''}
              onChange={handleEmbarqueChange}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Hora de Chegada"
              type="time"
              name="hora_chegada"
              value={currentEmbarque?.hora_chegada || ''}
              onChange={handleEmbarqueChange}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Hora de Entrada"
              type="time"
              name="hora_entrada"
              value={currentEmbarque?.hora_entrada || ''}
              onChange={handleEmbarqueChange}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Hora de Saída"
              type="time"
              name="hora_saida"
              value={currentEmbarque?.hora_saida || ''}
              onChange={handleEmbarqueChange}
              fullWidth
              margin="normal"
            />

            <Select
              label="Status"
              name="status"
              value={currentEmbarque?.status || ''}
              onChange={(e) => {
                handleEmbarqueChange(e);
                if (e.target.value === 'Liberado - Buscar NF-e') {
                  setModalType('imprimir');
                }
              }}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Aguarde Liberação p/ carregamento">Aguarde Liberação p/ carregamento</MenuItem>
              <MenuItem value="Liberado para Carregamento">Liberado para Carregamento</MenuItem>
              <MenuItem value="Processo em andamento">Processo em andamento</MenuItem>
              <MenuItem value="Liberado - Buscar NF-e">Liberado - Buscar NF-e</MenuItem>
            </Select>

            <Button
              variant="contained"
              onClick={handleSaveAndPrint}
              sx={{ mt: 2 }}
            >
              Salvar
            </Button>

            <Button
              variant="outlined"
              onClick={handleCloseModal}
              sx={{ mt: 2, ml: 2 }}
            >
              Fechar
            </Button>
          </>
        )}
      </Box>
    </Modal>

          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
              <TableCell>Data</TableCell>
                <TableCell>Transportadora</TableCell>
                <TableCell>Motorista</TableCell>
                <TableCell>Placas</TableCell>
                <TableCell>Produto</TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>NF-e</TableCell>
                <TableCell>Hora de Chegada</TableCell>
                <TableCell>Hora de Entrada</TableCell>
                <TableCell>Hora de Saída</TableCell>
                <TableCell>Status</TableCell>

                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {paginatedEmbarques.map((emb) => (
    <TableRow key={emb.id}>
      <TableCell>{formatDate(emb.data)}</TableCell>
      <TableCell>{emb.transportadora}</TableCell>
      <TableCell>{emb.motorista}</TableCell>
      <TableCell>{emb.placas}</TableCell>
      <TableCell>{emb.produto}</TableCell>
      <TableCell>{emb.empresa}</TableCell>
      <TableCell>{emb.nfe}</TableCell>
      <TableCell>{emb.hora_chegada}</TableCell>
      <TableCell>{emb.hora_entrada}</TableCell>
      <TableCell>{emb.hora_saida}</TableCell>
      <TableCell>{emb.status}</TableCell>

      <TableCell>
        <IconButton onClick={() => handleOpenModal('visualizar', emb)}><Visibility /></IconButton>
        <IconButton onClick={() => handleOpenModal('editar', emb)}><Edit /></IconButton>
        {/* Renderiza o botão Delete apenas se nivelAcesso for diferente de 2 */}
        {nivelAcesso !== 2 && (
          <IconButton onClick={() => handleDelete(emb.id)}><Delete /></IconButton>
        )}
      </TableCell>
    </TableRow>
  ))}
</TableBody>

            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography>Total de Registros: {filteredEmbarques.length}</Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>

          <Pagination
            count={Math.ceil(filteredEmbarques.length / itemsPerPage)}
            page={currentPage}
            onChange={(e, page) => setCurrentPage(page)}
            sx={{ mt: 2 }}
          />
        </Box>
      </Box>
      <Footer/>

      
    </>
  );
};

export default ControleEmbarque;
