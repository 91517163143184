import React, { useState } from 'react';
import {
  Container, Grid, TextField, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Box
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Print as PrintIcon, Save as SaveIcon } from '@mui/icons-material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Relatorios() {
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [filteredData, setFilteredData] = useState(null); // Para armazenar os dados filtrados

  // Exemplo de dados fictícios
  const initialData = [
    { mes: 'Janeiro', transacoes: 50 },
    { mes: 'Fevereiro', transacoes: 75 },
    { mes: 'Março', transacoes: 100 },
    { mes: 'Abril', transacoes: 125 },
    { mes: 'Maio', transacoes: 150 },
  ];

  // Função para filtrar os dados
  const handleSearch = () => {
    // Aqui podemos adicionar lógica para filtrar por data
    setFilteredData(initialData); // Neste exemplo, não estamos filtrando por data ainda
  };

  const dataForChart = {
    labels: filteredData ? filteredData.map((d) => d.mes) : [],
    datasets: [
      {
        label: 'Total de Transações',
        data: filteredData ? filteredData.map((d) => d.transacoes) : [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const optionsForChart = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Relatório de Transações Mensais',
      },
    },
  };

  const handlePrint = () => {
    window.print(); // Função simples para imprimir
  };

  const handleSave = () => {
    // Função para exportar ou salvar o relatório (pode ser implementada como desejado)
    alert('Salvar funcionalidade ainda não implementada');
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ my: 4, textAlign: 'center' }}>
        Relatórios de Transações
      </Typography>

      {/* Barra de filtro de data */}
      <Paper sx={{ p: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <TextField
              label="Data Inicial"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              label="Data Final"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button variant="contained" color="primary" fullWidth sx={{ height: '100%' }} onClick={handleSearch}>
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Resultados do gráfico e da tabela */}
      {filteredData && (
        <Box>
          {/* Gráfico de barras */}
          <Paper sx={{ p: 4, mb: 4 }}>
            <Bar data={dataForChart} options={optionsForChart} />
          </Paper>

          {/* Tabela com os resultados */}
          <Paper sx={{ p: 4, mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Tabela de Transações</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Mês</TableCell>
                  <TableCell>Total de Transações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((d, index) => (
                  <TableRow key={index}>
                    <TableCell>{d.mes}</TableCell>
                    <TableCell>{d.transacoes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          {/* Botões para imprimir e salvar */}
          <Box sx={{ textAlign: 'right', mb: 4 }}>
            <IconButton color="primary" onClick={handlePrint}>
              <PrintIcon /> {/* Ícone de impressão */}
            </IconButton>
            <IconButton color="primary" onClick={handleSave}>
              <SaveIcon /> {/* Ícone de salvar */}
            </IconButton>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default Relatorios;
