import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableHead, TableRow, TableCell, TableBody,
  Button, TextField, Box, Modal, Typography, 
  IconButton, InputAdornment, Pagination
} from '@mui/material';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const CadastroProdutor = () => {
  const [produtores, setProdutores] = useState([]);
  const [filteredProdutores, setFilteredProdutores] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [currentProdutor, setCurrentProdutor] = useState(null);
  const [modalType, setModalType] = useState('visualizar'); // 'adicionar' | 'editar' | 'visualizar'
  const itemsPerPage = 10;

  const [nivelAcesso, setNivelAcesso] = useState(null);

useEffect(() => {
  // Recupera o nível de acesso do localStorage
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  setNivelAcesso(userProfile ? userProfile.nivelAcesso : null);

  // Carrega os produtores ao montar o componente
  fetchProdutores();
}, []);

  const fetchProdutores = async () => {
    const response = await axios.get('https://coopergraos.com.br/api_nova/produtores.php');
    setProdutores(response.data);
    setFilteredProdutores(response.data);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = produtores.filter((prod) =>
      prod.nome_produtor.toLowerCase().includes(value)
    );
    setFilteredProdutores(filtered);
  };

  const handleOpenModal = (type, produtor = null) => {
    setModalType(type);
    setCurrentProdutor(
      produtor || {
        nome_produtor: ''
      }
    );
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleProdutorChange = (e) => {
    const { name, value } = e.target;
    setCurrentProdutor({ ...currentProdutor, [name]: value });
  };

  const handleSalvarProdutor = async () => {
    if (modalType === 'editar') {
      await axios.put('https://coopergraos.com.br/api_nova/produtores.php', currentProdutor);
    } else {
      await axios.post('https://coopergraos.com.br/api_nova/produtores.php', currentProdutor);
    }
    fetchProdutores();
    handleCloseModal();
  };

  const handleDelete = async (id) => {
    await axios.delete('https://coopergraos.com.br/api_nova/produtores.php', { data: { id } });
    fetchProdutores();
  };

  const paginatedProdutores = filteredProdutores.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Header pageTitle="Cadastro de Produtor" />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box sx={{ flexGrow: 1, p: 7 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenModal('adicionar')}
            sx={{ mb: 2 }}
          >
            Adicionar Produtor
          </Button>

          <TextField
            placeholder="Pesquisar por Nome do Produtor"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Modal open={openModal} onClose={handleCloseModal}>
            <Box
              sx={{
                width: '80%',
                maxWidth: 600,
                margin: 'auto',
                mt: 10,
                p: 3,
                backgroundColor: 'white',
                borderRadius: 2,
                overflowY: 'auto',
                maxHeight: '80vh',
              }}
            >
              <Typography variant="h6">
                {modalType === 'adicionar' ? 'Adicionar Produtor' : 'Editar Produtor'}
              </Typography>

              <TextField
                label="Nome do Produtor"
                name="nome_produtor"
                value={currentProdutor?.nome_produtor}
                onChange={handleProdutorChange}
                fullWidth
                margin="normal"
              />

              <Button
                variant="contained"
                onClick={handleSalvarProdutor}
                sx={{ mt: 2 }}
              >
                Salvar
              </Button>
            </Box>
          </Modal>

          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>Nome do Produtor</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {paginatedProdutores.map((prod) => (
    <TableRow key={prod.id}>
      <TableCell>{prod.nome_produtor}</TableCell>
      <TableCell>
        <IconButton onClick={() => handleOpenModal('visualizar', prod)}><Visibility /></IconButton>
        <IconButton onClick={() => handleOpenModal('editar', prod)}><Edit /></IconButton>
        {/* Renderiza o botão Delete apenas se nivelAcesso for diferente de 2 */}
        {nivelAcesso !== 2 && (
          <IconButton onClick={() => handleDelete(prod.id)}><Delete /></IconButton>
        )}
      </TableCell>
    </TableRow>
  ))}
</TableBody>
          </Table>

          <Pagination
            count={Math.ceil(filteredProdutores.length / itemsPerPage)}
            page={currentPage}
            onChange={(e, page) => setCurrentPage(page)}
            sx={{ mt: 2 }}
          />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default CadastroProdutor;
