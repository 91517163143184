import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableHead, TableRow, TableCell, TableBody,
  Button, TextField, Box, Modal, Typography, 
  IconButton, InputAdornment, Pagination
} from '@mui/material';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import InputMask from 'react-input-mask';

const CadastroMotorista = () => {
  const [motoristas, setMotoristas] = useState([]);
  const [filteredMotoristas, setFilteredMotoristas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [currentMotorista, setCurrentMotorista] = useState(null);
  const [modalType, setModalType] = useState('visualizar');
  const itemsPerPage = 10;
  const [nivelAcesso, setNivelAcesso] = useState(null); // Novo estado para o nível de acesso

  useEffect(() => {
    // Recupera o nível de acesso do localStorage
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    setNivelAcesso(userProfile ? userProfile.nivelAcesso : null);

    // Busca os motoristas ao carregar o componente
    fetchMotoristas();
  }, []);

  const fetchMotoristas = async () => {
    const response = await axios.get('https://coopergraos.com.br/api_nova/motoristas.php');
    setMotoristas(response.data);
    setFilteredMotoristas(response.data);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    
    const filtered = motoristas.filter((mot) =>
      Object.values(mot).some((field) =>
        String(field).toLowerCase().includes(value)
      )
    );
    
    setFilteredMotoristas(filtered);
  };

  const handleOpenModal = (type, motorista = null) => {
    setModalType(type);
    setCurrentMotorista(
      motorista || {
        nome: '',
        placa: '',
        empresa: '',
        telefone: '',
      }
    );
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleMotoristaChange = (e) => {
    const { name, value } = e.target;
    setCurrentMotorista({ ...currentMotorista, [name]: value });
  };

  const handleSalvarMotorista = async () => {
    if (modalType === 'editar') {
      await axios.put('https://coopergraos.com.br/api_nova/motoristas.php', currentMotorista);
    } else {
      await axios.post('https://coopergraos.com.br/api_nova/motoristas.php', currentMotorista);
    }
    fetchMotoristas();
    handleCloseModal();
  };

  const handleDelete = async (id) => {
    await axios.delete('https://coopergraos.com.br/api_nova/motoristas.php', { data: { id } });
    fetchMotoristas();
  };

  const paginatedMotoristas = filteredMotoristas.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Header pageTitle="Cadastro de Motorista" />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box sx={{ flexGrow: 1, p: 7 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenModal('adicionar')}
            sx={{ mb: 2 }}
          >
            Adicionar Motorista
          </Button>

          <TextField
            placeholder="Pesquisar por Nome, Empresa ou Placa"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Modal open={openModal} onClose={handleCloseModal}>
            <Box
              sx={{
                width: '80%',
                maxWidth: 600,
                margin: 'auto',
                mt: 10,
                p: 3,
                backgroundColor: 'white',
                borderRadius: 2,
                overflowY: 'auto',
                maxHeight: '80vh',
              }}
            >
              <Typography variant="h6">
                {modalType === 'adicionar' ? 'Adicionar Motorista' : 'Editar Motorista'}
              </Typography>
              <TextField
                label="Nome"
                name="nome"
                value={currentMotorista?.nome}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (/^[A-ZÀ-ÿ\s]*$/.test(value)) { 
                    handleMotoristaChange({
                      target: { name: 'nome', value }
                    });
                  }
                }}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Placa"
                name="placa"
                value={currentMotorista?.placa}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (/^[A-Z0-9]*$/.test(value)) {
                    handleMotoristaChange({
                      target: { name: 'placa', value }
                    });
                  }
                }}
                fullWidth
                margin="normal"
              />

              <InputMask
                mask="(99) 99999-9999"
                value={currentMotorista?.telefone}
                onChange={handleMotoristaChange}
              >
                {() => (
                  <TextField
                    label="Telefone (WhatsApp)"
                    name="telefone"
                    fullWidth
                    margin="normal"
                  />
                )}
              </InputMask>

              <Button
                variant="contained"
                onClick={handleSalvarMotorista}
                sx={{ mt: 2 }}
              >
                Salvar
              </Button>
            </Box>
          </Modal>

          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Placa</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedMotoristas.map((mot) => (
                <TableRow key={mot.id}>
                  <TableCell>{mot.nome}</TableCell>
                  <TableCell>{mot.placa}</TableCell>
                  <TableCell>{mot.telefone}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenModal('visualizar', mot)}><Visibility /></IconButton>
                    <IconButton onClick={() => handleOpenModal('editar', mot)}><Edit /></IconButton>
                    {/* Renderiza o botão Delete apenas se nivelAcesso for diferente de 2 */}
                    {nivelAcesso !== 2 && (
                      <IconButton onClick={() => handleDelete(mot.id)}><Delete /></IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination
            count={Math.ceil(filteredMotoristas.length / itemsPerPage)}
            page={currentPage}
            onChange={(e, page) => setCurrentPage(page)}
            sx={{ mt: 2 }}
          />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default CadastroMotorista;
