import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Relatorios from './components/Relatorios';
import Usuarios from './components/Usuarios';
import UsuarioDashboard from './components/UsuarioDashboard';
import Motoristas from './components/Motoristas';
import ConferenciaPlacas from './components/ConferenciaPlacas';
import Login from './components/Login';
import Status from './components/Status';
import ControleEmbarque from './components/ControleEmbarque';
import DashbordPrincipal from './components/DashbordPrincipal';
import ControleEntradaProduto from './components/ControleEntradaProduto';
import CadastroMotorista from './components/CadastroMotorista';
import CadastroProdutor from './components/CadastroProdutor';
import StatusInterno from './components/StatusInterno';
import StatusExterno from './components/StatusExterno'; // Criar este componente para o status externo

import { Box } from '@mui/material';

function App() {
  const [userProfile, setUserProfile] = useState(null); // Estado para armazenar o perfil do usuário
  const navigate = useNavigate();

  useEffect(() => {
    // Detecta o subdomínio atual
    const hostname = window.location.hostname;

    if (hostname === 'interno.coopergraos.com.br') {
      navigate('/status-interno'); // Redireciona para a rota interna
    } else if (hostname === 'externo.coopergraos.com.br') {
      navigate('/status-externo'); // Redireciona para a rota externa
    }
  }, [navigate]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}> {/* Garantindo que o layout ocupe 100% da largura */}
      <Box
        sx={{
          flexGrow: 1,
          p: 3, // Espaçamento interno
          backgroundColor: '#f5f5f5',
          minHeight: '100vh',
          width: '100%', // O Box agora ocupa 100% da largura
        }}
      >
        <Routes>
          <Route path="/" element={<Status />} />
          <Route path="/admin" element={<Login setUserProfile={setUserProfile} />} />
          <Route path="/login" element={<Login setUserProfile={setUserProfile} />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/status-interno" element={<StatusInterno />} /> {/* Rota para Status Interno */}
          <Route path="/status-externo" element={<StatusExterno />} /> {/* Rota para Status Externo */}

          <Route path="/DashbordPrincipal" element={<DashbordPrincipal />} />
          <Route path="/relatorios" element={<Relatorios />} />
          <Route path="/usuarios" element={<Usuarios />} />
          <Route path="/usuario-dashboard" element={<UsuarioDashboard />} />
          <Route path="/motoristas" element={<Motoristas />} />
          <Route path="/conferencia-placas" element={<ConferenciaPlacas />} />
          <Route path="/ControleEmbarque" element={<ControleEmbarque />} />
          <Route path="/ControleEntrada" element={<ControleEntradaProduto />} />
          <Route path="/CadastroMotorista" element={<CadastroMotorista />} />
          <Route path="/CadastroProdutor" element={<CadastroProdutor />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
