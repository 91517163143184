import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Snackbar, Alert } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importando useNavigate para redirecionamento

const useStyles = {
  appBar: {
    backgroundColor: '#34a853',
    height: '64px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  weatherInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: '#FFCC00',
    padding: '4px 8px',
    borderRadius: '8px',
  },
  weatherText: {
    color: 'green',
  },
  userNameBox: {
    backgroundColor: '#1B5E20',
    color: '#FFFFFF',
    padding: '4px 12px',
    borderRadius: '8px',
  },
};

const Header = ({ pageTitle }) => {
  const [userName, setUserName] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [weather, setWeather] = useState(null);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate(); // Hook para redirecionamento

  // Função de logout
  const handleLogout = () => {
    localStorage.removeItem('token'); // Exemplo: Remove o token do localStorage
    localStorage.removeItem('userProfile'); // Remove informações do usuário
    navigate('/login'); // Redireciona para a tela de login
  };

  useEffect(() => {
    const storedProfile = JSON.parse(localStorage.getItem('userProfile'));
    if (storedProfile && storedProfile.nome) {
      setUserName(storedProfile.nome);
    }
  }, []);

  const isWeekday = () => {
    const day = new Date().getDay();
    return day >= 1 && day <= 5;
  };

  const checkMessages = (hours) => {
    if (!isWeekday()) return;

    let message = '';
    switch (hours) {
      case 7:
        message = 'Bom trabalho! 🌞';
        break;
      case 11:
        message = 'Hora do almoço! 🍽️';
        break;
      case 13:
        message = 'Bom trabalho à tarde! ☕';
        break;
      case 17:
        message = 'Bom descanso e até amanhã! 🌙';
        break;
      default:
        return;
    }

    setMessage(message);
    setShowMessage(true);

    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const formattedTime = now.toLocaleTimeString('pt-BR', {
        timeZone: 'America/Cuiaba',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });

      setCurrentTime(formattedTime);
      checkMessages(hours);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(
          'https://apiprevmet3.inmet.gov.br/previsao/78890000'
        );
        const weatherData = response.data['78890000'][new Date().toISOString().slice(0, 10)];
        setWeather({
          temp: `${weatherData.manha.temp_min}°C / ${weatherData.tarde.temp_max}°C`,
          description: weatherData.manha.resumo,
        });
      } catch (error) {
        console.error('Erro ao buscar dados de clima:', error);
        setWeather({
          temp: '24°C / 32°C',
          description: 'Parcialmente nublado',
        });
      }
    };

    fetchWeather();
  }, []);

  return (
    <>
      <AppBar position="fixed" sx={useStyles.appBar}>
        <Toolbar sx={useStyles.toolbar}>
          <Typography variant="h6" noWrap>
            {pageTitle}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '32px' }}>
            <Box sx={useStyles.weatherInfo}>
              <Typography sx={useStyles.weatherText}>
                {weather ? `${weather.temp} - ${weather.description}` : 'Carregando...'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <AccessTimeIcon style={{ color: 'white' }} />
              <Typography sx={{ color: 'white' }}>{currentTime}</Typography>
            </Box>

            <Box sx={useStyles.userNameBox}>{userName}</Box>

            <IconButton onClick={handleLogout}>
              <ExitToAppIcon style={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Snackbar open={showMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="info" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Header;
