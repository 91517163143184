import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, Modal, Box, TextField, Typography, MenuItem, Container
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Sidebar from '../components/Sidebar'; 
import Header from '../components/Header'; 
import Footer from '../components/Footer'; 

const useStyles = {
  content: {
    flexGrow: 1,
    padding: '24px',
    marginLeft: 240, 
    marginTop: '80px', 
    marginBottom: '15px', 
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    p: 4,
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
};

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]); // Lista de usuários
  const [openModal, setOpenModal] = useState(false); // Controle do modal
  const [selectedUser, setSelectedUser] = useState(null); // Usuário selecionado para edição
  const [nome, setNome] = useState(''); 
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [nivelAcesso, setNivelAcesso] = useState(1); 
  const [modalType, setModalType] = useState(''); // Tipo do modal (add/edit)

  useEffect(() => {
    fetchUsuarios(); // Busca os usuários ao carregar o componente
  }, []);

  // Função para buscar usuários
  const fetchUsuarios = async () => {
    try {
      const response = await axios.get('https://coopergraos.com.br/api_nova/usuarios.php');
      console.log('Dados recebidos:', response.data); // Verificar os dados retornados
      setUsuarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };
  

  // Abre o modal de adicionar ou editar
  const handleOpenModal = (type, user = null) => {
    setModalType(type);
    setSelectedUser(user);
    setNome(user?.nome || '');
    setEmail(user?.email || '');
    setNivelAcesso(user?.niveis_acesso_id || 1); 
    setSenha('');
    setOpenModal(true);
  };

  // Fecha o modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Submete os dados do usuário (add ou edit)
  const handleSubmit = async () => {
    // Cria o payload sem a senha inicialmente
    const payload = { 
      nome, 
      email, 
      niveis_acesso_id: nivelAcesso 
    };
  
    // Se uma senha foi preenchida, adiciona ao payload
    if (senha) {
      payload.senha = senha;
    }
  
    // Adiciona o ID no caso de edição
    if (selectedUser) {
      payload.id = selectedUser.id;
    }
  
    try {
      const response = await axios.post(
        'https://coopergraos.com.br/api_nova/usuarios.php',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );
      console.log('Usuário salvo:', response.data);
      fetchUsuarios(); // Atualiza a lista de usuários
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar usuário:', error.response?.data || error.message);
    }
  };
  

  // Deleta um usuário
  const handleDelete = async (id) => {
    try {
      await axios.delete('https://coopergraos.com.br/api_nova/usuarios.php', {
        data: { id },
      });
      fetchUsuarios(); // Atualiza a lista de usuários após a exclusão
    } catch (error) {
      console.error('Erro ao remover usuário:', error);
    }
  };

  // Função para obter o nome do nível de acesso
  const getNivelAcessoNome = (nivelAcessoId) => {
    console.log('Nível de Acesso Recebido:', nivelAcessoId); // Log para garantir o valor correto
    switch (parseInt(nivelAcessoId, 10)) {
      case 1:
        return 'Administrador';
      case 2:
        return 'Usuário';
      default:
        return 'Desconhecido';
    }
  };
  
  
  return (
    <>
      <Header pageTitle="Usuários" />
      <Sidebar />

      <main style={useStyles.content}>
        <Container>
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => handleOpenModal('add')}
            sx={{ marginBottom: '20px' }}
          >
            Adicionar Usuário
          </Button>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Nível de Acesso</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usuarios.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.nome}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{getNivelAcessoNome(user.niveis_acesso_id)}</TableCell>
                    <TableCell>
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => handleOpenModal('edit', user)}
                      >
                        Editar
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDelete(user.id)}
                        sx={{ marginLeft: '10px' }}
                      >
                        Remover
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal open={openModal} onClose={handleCloseModal}>
            <Box sx={useStyles.modalBox}>
              <Typography variant="h6">
                {modalType === 'add' ? 'Adicionar Usuário' : 'Editar Usuário'}
              </Typography>
              <TextField
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                fullWidth
                sx={{ marginBottom: '10px' }}
              />
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                sx={{ marginBottom: '10px' }}
              />
              <TextField
                label="Senha"
                type="password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                fullWidth
                sx={{ marginBottom: '10px' }}
              />
              <TextField
                  label="Nível de Acesso"
                  value={nivelAcesso}
                  onChange={(e) => setNivelAcesso(parseInt(e.target.value, 10))}
                  select
                  fullWidth
                >
                  <MenuItem value={1}>Administrador</MenuItem>
                  {/* Remova o MenuItem abaixo para exibir apenas a opção "Administrador" */}
                  <MenuItem value={2}>Usuário</MenuItem>
                </TextField>

              <Button onClick={handleSubmit} sx={{ marginTop: '10px' }} variant="contained">
                {modalType === 'add' ? 'Adicionar' : 'Salvar'}
              </Button>
            </Box>
          </Modal>
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default Usuarios;
